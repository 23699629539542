import React, {useEffect, useState, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useQueryParam, StringParam} from 'use-query-params'
import {OutlinedButton} from 'src/components/buttons/Primary'
import Heading from 'src/components/typography/Heading'
import Paragraph from 'src/components/typography/Paragraph'
import {useVisitorApi} from "../../../hooks/useApi";
import useLocalizedRoutes from "../../../hooks/useLocalizedRoutes";
import Success from "../../forms/Success";
import {Context as ProfileContext} from "../../../context/ProfileContext";

export default function VerificationEmailBookingDetails({email, revisit, location}) {
    const {t} = useTranslation();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [token] = useQueryParam("verification_token", StringParam);
    const { verifyEmail, resendVerification} = useVisitorApi();
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const {bookingDetailsReview} = useLocalizedRoutes();
    const [successMsg, setSuccessMsg] = useState(false);
    const {state: {profile }} = useContext(ProfileContext);
    const [setError] = useState(false);

    async function verify() {
        try {
            await verifyEmail({'verification_token': token})
            setIsSuccess(true);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    async function resendEmailVerification() {
        setLoading(true)
        const redirectUrl = location.origin + bookingDetailsReview;
        const res = await resendVerification(redirectUrl);
        if (res.status === 200) {
            setEmailSent(true)
            setSuccessMsg(true)
            setTimeout(() => setSuccessMsg(false), 3000)
        }
        else setError(Object.values(res.errors).toString());
        setLoading(false)
    }

    useEffect(() => {
        if (revisit) {
            verify()
        }
    }, [revisit])

    function conditionalRender() {
        if (revisit) return (
            <div>
                <Heading type='sub-title'>{t('student.verifyEmail.success.title')}</Heading>
                <div className="py-10">
                    <Paragraph className="my-2">{t('student.verifyEmail.success.body')}</Paragraph>
                </div>
            </div>
        )

        return (
            <div>
                <Heading type='sub-title'>{t('student.confirmEmail.title')}</Heading>
                <div className="py-10">
                    <Paragraph className="my-2">{t('student.confirmEmail.body', {email: email})}</Paragraph>
                </div>
                <OutlinedButton isLoading={loading} onClick={() => resendEmailVerification()}>{t('student.confirmEmail.button')}</OutlinedButton>
                {successMsg && <Success className="">{t('general.verifyEmail', {email: profile.email})}</Success>}
            </div>
        )
    }

    return conditionalRender()
}
