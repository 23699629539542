import React, {useContext, useEffect, useState } from 'react'
import {useTranslation} from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { navigate } from 'gatsby'
import {Context as ThemeContext} from 'src/context/ThemeContext'
import {Context as BookingContext } from 'src/context/BookingContext'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import { usePageContext } from 'src/context/PageContext'
import { useVisitorApi } from 'src/hooks/useApi'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import BookATeacherBanner from 'src/components/sections/booking/BookATeacherBanner'
import YourLessons from 'src/components/sections/bookingDetails/YourLessons'
import FixedFooter from 'src/components/sections/teacherProfile/FixedFooter'
import StudentRegisterForm from 'src/components/register/StudentRegisterForm'
import VerificationEmail from 'src/components/sections/bookingDetails/VerificationEmailSent'
import useNoTeachersAllowed from 'src/hooks/useNoTeachersAllowed'
import Error from 'src/components/forms/Error'

export default function BookingDetails(props) {
    const {t} = useTranslation();
    const {setTheme} = useContext(ThemeContext);
    const {state: {teacher, instrument, lessonPlan, lessonsToSchedule, comments }} = useContext(BookingContext);
    const {state: {profile}, toggleLogin} = useContext(ProfileContext);
    const { paths, lang } = usePageContext();
    const { location } = props;
    const [ verifyEmail, setVerifyEmail ] = useState(false);
    const [ userEmail, setUserEmail ] = useState(null)
    const [ showEmailSentMsg, setShowEmailSentMsg ] = useState(false);
    const [token] = useQueryParam("verification_token", StringParam);
    const showRegistrationForm = !profile && !verifyEmail && !showEmailSentMsg;
    const { studentAccount, bookingCompleted } = useLocalizedRoutes();
    const { bookALesson } = useVisitorApi();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState(null)

    useEffect(() => setTheme('inverse'), [])
    useNoTeachersAllowed()
    useEffect(() => {
        if(location.search) {
            setVerifyEmail(true)
        }
    }, [location, setVerifyEmail])

    async function completeBooking() {
        setLoading(true)
        let booking = {
            teacher: teacher.id,
            instrument,
            lessonPlan,
            lessonSchedule: lessonsToSchedule.map(({availabilityID}) => availabilityID),
            comments,
            redirectUrl: location.origin + bookingCompleted
        }

        const res = await bookALesson(booking)
        setLoading(false)
        if(res.checkout_url) {
            navigate(res.checkout_url)
        }
        else {
            setError(true)
            setErrorMsg(Object.values(res).toString())
        }
    }

    function checkIfBookingExists() {
        if (teacher) {
            return (
                <>
                    {(!showEmailSentMsg || !verifyEmail) && (
                        <>
                            <BookATeacherBanner teacher={teacher}/>
                            <YourLessons />
                        </>
                    )}
                    {renderRegistrationForm()}
                    {showVerificationEmailSent()}
                    {userComesBack()}
                    {error && <Error>{errorMsg}</Error>}
                    <FixedFooter reviewStage showNextButton={!!profile} completeBooking={completeBooking} loading={loading}/>
                </>
            )
        }

        return 'No booking details found'
    }


    function completeRegistration(email) {
        setShowEmailSentMsg(true)
        setUserEmail(email)
    }

    function renderRegistrationForm() {
        if (showRegistrationForm) return (
            <div>
                <p className="text-xl font-light md:text-3xl">
                    {t('bookingDetails.createAccount')}
                </p>
                <p className="mt-3">
                    <span>{t('bookingDetails.alreadyHaveAccount')} </span>
                    <span
                        className="text-dark-blue-600 underline cursor-pointer"
                        onClick={toggleLogin}
                    >
                        {t('bookingDetails.signIn')}
                    </span>
                </p>
                <StudentRegisterForm redirectUrl={location.origin + '/' + lang + paths[lang]} completionAction={completeRegistration}/>
            </div>
        )
    }

    function showVerificationEmailSent() {
        if (showEmailSentMsg) return (
            <div className="mt-12">
                <VerificationEmail location={location} email={userEmail} />
            </div>

        )
    }

    function userComesBack() {
        if(verifyEmail) return (
            <div className="mt-12">
                <VerificationEmail token={token} revisit/>
            </div>
        )
    }


    return (
        <MarketingLayout {...props} noFooter title={t('bookATeacher.title')}>
            <div className="container mx-auto px-6 lg:px-20 mb-32">
                {checkIfBookingExists()}
            </div>
        </MarketingLayout>
    )
}
