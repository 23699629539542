import React, { useContext, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Context as BookingContext } from 'src/context/BookingContext';
import PlanLesson from 'src/components/sections/booking/PlanLesson';

export default function YourLessons() {
    const { t } = useTranslation();
    const [openTimeSlot, setOpenTimeSlot] = useState(null);
    const { state: { lessonsToSchedule, enabledDays } } = useContext(BookingContext);

    lessonsToSchedule.sort((a, b) => {
        return DateTime.fromISO(a.selectedTime) - DateTime.fromISO(b.selectedTime);
    });

    return (
        <div className="my-10">
            <p className="text-xl font-light md:text-3xl">
                {t('bookingDetails.yourLessons')}
            </p>
            { lessonsToSchedule.map((lesson, index) => (
                <PlanLesson
                    lesson={lesson}
                    setOpenTimeSlot={setOpenTimeSlot}
                    openTimeSlot={openTimeSlot}
                    index={index}
                    key={lesson.id}
                    daysEnabled={enabledDays}
                    reviewStage
                />
            ))}
        </div>
    )
}
